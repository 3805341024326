import './AboutCom.css';
import React,{useEffect} from 'react';
import Nav from './Nav';
import Footer from './Footer';
import Contact from './Contact';
import { useNavigate } from 'react-router-dom';

const AboutCom = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
 },[])
  const navigarte=useNavigate();
  const handleClick=()=>{
    navigarte('/contactUs');
  }
  return (
    <div className='main-com'>
      <Nav />
      <div className="about-us-container">
        <h1 className='H1'>About Us</h1>
        <h2 className='H2'>Your Partner in IT Excellence</h2>
        <p>
          At QuantumLeap IT Solutions, we’re more than just a staffing and consultancy service—we’re your strategic partner in building a stronger, more agile IT workforce. Founded on the principles of integrity, innovation, and excellence, we are committed to delivering solutions that empower businesses to achieve their full potential.
        </p>

        <div className="about-us-section">
          <h3 className='H3'>Our Story</h3>
          <p>
            QuantumLeap IT Solutions was established with a clear mission: to bridge the gap between exceptional IT talent and the businesses that need it. We recognized the growing demand for highly skilled IT professionals and the challenges companies face in finding the right fit. With our deep understanding of the IT landscape, we set out to create a service that not only provides staffing solutions but also offers expert consultancy to help businesses navigate the complexities of the digital world.
          </p>
        </div>

        <div className="about-us-section">
          <h3 className='H3'>Our Mission</h3>
          <p>
            Our mission is to provide top-tier IT staffing and consultancy services that drive business success. We strive to be the go-to partner for companies looking to enhance their IT capabilities, whether through finding the perfect candidates for their team or offering strategic guidance on technology initiatives.
          </p>
        </div>

        <div className="about-us-section">
          <h3 className='H3'>Our Values</h3>
          <ul>
            <li><strong>Integrity:</strong> We conduct our business with the highest level of integrity, ensuring transparency and honesty in every interaction.</li>
            <li><strong>Excellence:</strong> We are dedicated to delivering excellence in everything we do, from the quality of our talent pool to the results of our consultancy services.</li>
            <li><strong>Innovation:</strong> We embrace innovation, constantly seeking new and better ways to serve our clients and stay ahead of industry trends.</li>
            <li><strong>Collaboration:</strong> We believe in the power of collaboration, both within our team and with our clients, to achieve the best possible outcomes.</li>
          </ul>
        </div>

        <div className="about-us-section">
          <h3 className='H3'>Why We Stand Out</h3>
          <ul>
            <li><strong>Extensive Network:</strong> Our vast network of IT professionals allows us to quickly and efficiently match businesses with the talent they need, whether for short-term projects or long-term engagements.</li>
            <li><strong>Expert Consultancy:</strong> Our team of seasoned consultants brings a wealth of knowledge and experience, helping clients overcome challenges and capitalize on opportunities in the ever-evolving IT landscape.</li>
            <li><strong>Client-Centric Approach:</strong> We place our clients at the center of everything we do, ensuring that our solutions are tailored to meet their unique needs and drive their success.</li>
          </ul>
        </div>

        <div className="about-us-section">
          <h3 className='H3'>Meet Our Team</h3>
          <p>
            Our team is composed of industry experts who are passionate about technology and dedicated to helping our clients succeed. We bring a diverse range of skills and experiences to the table, ensuring that we can address any IT challenge with confidence and creativity.
          </p>
        </div>

        <div className="about-us-section">
          <h3 className='H3'>Join Us</h3>
          <p>
            We’re always looking for talented IT professionals to join our network. If you’re passionate about technology and looking for new opportunities, we’d love to hear from you.
          </p>
        </div>

        <div className="about-us-section">
          <h3 className='H3'>Get in Touch</h3>
          <p>
            Ready to learn more about how QuantumLeap IT Solutions can help your business thrive? Contact us today to start the conversation.
          </p>
        </div>
        <div className='btncon'>
        <button onClick={handleClick}  className="cont-button">Contact Us</button>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default AboutCom;
