import "./About.css";
import Award from './Asset/js.jpg';
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate=useNavigate();
  const handleClick=()=>{
    navigate('/about')
  }
  return (
    <div className="a">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img
            src={Award}
            alt=""
            className="a-img"
          />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title"><strong>Why Choose Us</strong></h1>
        <p className="a-sub">
     
        With years of experience in the IT sector, we understand the unique demands of your business. Our team is well-versed in the latest technologies and industry trends, ensuring that we provide solutions that are both current and effective.
        </p>
        <h3 className="a-title"><strong> Personalized Service</strong></h3>
        <p className="a-sub">
       
        We believe in building long-term relationships with our clients. Our approach is tailored to your specific needs, ensuring that we deliver solutions that align with your business objectives.
         </p>
        
        <h3 className="a-title"><strong>   Industry Expertise</strong></h3>
        <p className="a-sub">
        With years of experience in the IT sector, we understand the unique demands of your business. Our team is well-versed in the latest technologies and industry trends, ensuring that we provide solutions that are both current and effective.
        </p>
        
        <button className='btn2' onClick={handleClick}>Know more</button>
        
       
      </div>
    </div>
  );
};

export default About;