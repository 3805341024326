// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Main';


import AboutCom from './Component/AboutCom';
import Servises from './Component/Servises';
import Resource from './Component/Resource';
import firebaseApp from "./firebase";

import Contus from './Component/Contus';

function App() {
  return (
    
      <div>
      <Routes>
      <Route path="/" element={<Main />} />
      
      <Route path="/contactUs" element={<Contus />} />
      <Route path="/services" element={<Servises/>} />
      <Route path="/resource" element={<Resource/>} />
      <Route path="/about" element={<AboutCom/>} />
      </Routes>
      </div>
  
  );
}

export default App;
