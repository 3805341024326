import firebase from "firebase/compat/app";
import 'firebase/compat/firestore'
import React from 'react';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'quantumleap-6d12c.firebaseapp.com',
  projectId: 'quantumleap-6d12c',
  storageBucket: 'quantumleap-6d12c.appspot.com',
  messagingSenderId: '909585987715',
  appId: '1:909585987715:web:b1ad930022e7241d6c43b6',
  measurementId: 'G-64TJ7DHGYW',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);


export const db = firebaseApp.firestore(); // Example: Export Firestore instance

export default firebaseApp;